import React, { useState } from "react";
import styled from "@emotion/styled";

const FooterContainer = styled.div`
  align-items: center;
  height: 30vh;
  width: 100vw;
  justify-content: center;
`;

const FooterLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 50%;
  color: #2dc18c;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  font-family: Nunito Sans, sans-serif;
`;

const TitleContainer = styled.div`
  text-align: center;
  width: 100vw;
  height: 50%;
  align-items: center;
  font-size: 57px;
  color: rgba(36, 39, 63, 1);
  font-weight: 600;
  font-family: Nunito Sans, sans-serif;
`;

const BlogContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 15px;
`;

const BlogColumn = styled.div`
  flex: 1;
  align-items: center;
`;

const BlogElementContainer = styled.div`
  align-items: center;
  margin: 20px;
  padding: 15px;
  border-radius: 15px;
  background-color: rgba(240, 247, 245, 1);
`;

const ItemTitle = styled.div`
  color: #2dc18c;
  text-align: left;
  font-size: 34px;
  font-weight: 600;
  font-family: Nunito Sans, sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ItemHeadline = styled.div`
  align-items: center;
`;

const ContentList = ({ filteredData }) => {
  if (filteredData === undefined) return <div></div>;

  return (
    <div>
      <FooterContainer>
        <FooterLinkContainer>
          <a href="/">Home / Blog</a>
        </FooterLinkContainer>

        <TitleContainer>Blog</TitleContainer>
      </FooterContainer>

      <BlogContainer>
        <BlogColumn>
          {filteredData
            .filter((item) => item.slug.indexOf("tr") === -1)
            .map(function (d, idx) {
              return (
                <BlogElementContainer>
                  <ItemTitle>
                    <a href={d.slug}>{d.title}</a>
                  </ItemTitle>

                  <ItemHeadline>
                    <a href={d.slug}>{d.headline}</a>
                  </ItemHeadline>
                </BlogElementContainer>
              );
            })}
        </BlogColumn>
      </BlogContainer>
    </div>
  );
};

export default ContentList;
