import React, { useState } from "react";
import ContentList from "./ContentList";

import jsonData from "../../data/data-blog.json";

const Blog = (props) => {
  const loadData = JSON.parse(JSON.stringify(jsonData));
  const filteredData = loadData.map((item) => {
    const data = {
      title: item.title,
      headline: item.headline,
      slug: item.slug,
    };

    return data;
  });

  return <ContentList filteredData={filteredData} />;
};

export default Blog;
